import { filterByPrefix } from './helper';

/*
 * Generates receipt headers from query params and cookies.
 *
 * This is used for injecting a metadata file into the resulting project, which is
 * read by the IDE.
 */
export function collectReceiptHeaders(initialQuery) {
  try {
    if (!hasAnalyticsStorage()) {
      return false;
    }
    const utmQueryParams = filterByPrefix(initialQuery, 'utm');
    return Object.keys(utmQueryParams).length > 0 ?
      { 'X-Ktor-Receipt': JSON.stringify(utmQueryParams) } :
      {};
  } catch (e) {
    console.warn('Failed to generate receipt headers', e);
    return {};
  }
}

/*
 * Returns true when cookie consent includes analytics storage.
 */
export function hasAnalyticsStorage() {
  // Check if the "cookie_consent" cookie exists
  if (document.cookie.indexOf('cookie_consent') === -1) {
    return false;
  }

  // Get the value of the "cookie_consent" cookie
  const cookies = document.cookie.split(';');
  const cookieVal = cookies.find((cookie) => cookie.trim().startsWith('cookie_consent='));

  // Check if the value includes 'analytics_storage'
  return cookieVal && cookieVal.indexOf('analytics_storage') !== -1;
}

/*
 * Gets the value of the last_utm_parameters cookie and parses it as a URL parameter string.
 *
 * Returns empty object when the cookie is unavailable or invalid.
 */
export function getLastUtmParametersAsObject() {
  const cookies = document.cookie.split(';');
  const utmCookie = cookies.find((cookie) => cookie.trim().startsWith('last_utm_parameters='));

  if (!utmCookie) {
    return {};
  }

  try {
    const utmValue = decodeURIComponent(utmCookie.trim().substring('last_utm_parameters='.length));
    const utmParams = new URLSearchParams(utmValue);
    const paramsObj = {};

    utmParams.forEach((value, key) => {
      paramsObj[key] = value;
    });

    return filterByPrefix(paramsObj, 'utm');
  } catch (error) {
    console.warn('Failed to parse last_utm_parameters; returning empty', error);
    return {};
  }
}
